.btn {
  width: 100%;
}

.btn.icon-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  min-height: 38px;
}

.alternatives-form {
  min-height: 110px;
}

.pallets-info {
  font-weight: bold;
}
.pallets-info.error {
  color: red;
}
.pallets-info.success {
  color: green;
}
.pallets-info.warning {
  color: #A9A900;
}

.alternatives {
  margin-top: 10px;
  margin-bottom: 30px;
}

.alternative {
  min-height: 230px;
  display: flex;
  flex-direction: column;
}
.alternative .card-body {
  display: flex;
  flex-direction: column;
}
.alternative p {
  margin-bottom: 2px;
}
.alternative .card-kpis p {
  display: flex;
  flex-direction: column;
}
.alternative .card-kpis {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
}
.alternative .card-kpis p {
  width: 50%;
}
.alternative .card-kpis p:first-of-type {
  width: 100%;
}
.alternative .card-kpis p.red {
  color: red;
}

.alternative .card-title {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 1.75rem;
}
.alternative .card-title img {
  height: 1.5rem;
  margin-top: -0.5rem;
}

.alternative .product-name {
  font-weight: bold;
  line-height: 0.8rem;
  min-height: 1.8rem;
}

.alternative .controls {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 15px;
  font-size: 1.15rem;
}

.alternative .controls .control-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 100%;
  min-height: 40px;
  text-align: center;
  border-radius: 5px;
}
.alternative .controls .control-button:not(.disabled):hover {
  background-color: #BBFFCA;
  cursor: pointer;
}
.alternative .controls .control-button:not(.disabled):hover.del {
  background-color: #FFDBDB;
}

.alternative .controls .counter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #AAAAAA;
  padding: 2px 6px;
  width: 68%;
}
.alternative .controls.disabled .counter {
  border: 1px solid #AAAAAA;
  background-color: #DDDDDD;
}

.alternative .controls .counter>div {
  height: 100%;
  width: 33%;
  border-radius: 5px;
  text-align: center;
}

.alternative .controls .count {
  padding: 0 5px;
}

.alternative .counter .add, .alternative .counter .del {
  font-size: 1.8rem;
  text-align: center;
  cursor: pointer;
  padding: 0 10px;
  height: 100%;
  border-radius: 5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.alternative .disabled .counter .add, .alternative .disabled .counter .del {
  cursor: not-allowed;
}
.alternative .counter .add.disabled, .alternative .counter .del.disabled {
  cursor: not-allowed;
}
.alternative .controls:not(.disabled) .counter .add:not(.disabled):hover {
  background-color: #BBFFCA;
}
.alternative .controls:not(.disabled) .counter .del:not(.disabled):hover {
  background-color: #FFDBDB;
}