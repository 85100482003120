.content {
  background-color: #ffffff;
  color: black;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 70px;
  padding-top: 10px;
  -webkit-overflow-scrolling: touch;
}

.lang-selector-div {
  width: 100%;
}
